import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SEOService } from './seo-service/seo.service';
import { ThemeService } from './theme-service/theme.service';
import { LocalstorageService } from './localstorage-service/localstorage.service';
import { ExternalContentService } from './external-content-service/external-content.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {UrlService} from './url-service/url.service';
import {SeoBuilderService} from './seo-builder/seo-builder.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  providers: [
    SEOService,
    ThemeService,
    LocalstorageService,
    ExternalContentService,
    UrlService,
    SeoBuilderService,
  ]
})
export class ServiceModule {}
