import {Component, OnInit} from '@angular/core';
import {ExternalContentService} from '../../service/external-content-service/external-content.service';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {SEOService} from '../../service/seo-service/seo.service';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'air-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss']
})
export class PrivacyPageComponent implements OnInit {

  currentAllowedExternalContent: string[];

  faChevronRight = faChevronRight as IconDefinition;

  constructor(
    public externalContentService: ExternalContentService,
    protected seoService: SEOService,
    ) {
  }

  ngOnInit(): void {

    this.seoService.setPageTitle('menu.privacy');
    this.seoService.updateKeyWords(['datenschutz', 'privacy'])
    this.setAllowedExternalContent();
  }

  setAllowedExternalContent() {
    if (!this.isAllExternalContentAllowed() && this.externalContentService.isAnyExternalContentAllowed()) {
      this.currentAllowedExternalContent = this.externalContentService.getAllowedExternalContent();
    }
  }

  allowAllExternalContent() {
    this.externalContentService.allowAllExternalContent();
    this.currentAllowedExternalContent = [];
  }

  removeAllExternalContentPermissions() {
    this.externalContentService.removeAllPermissions();
    this.currentAllowedExternalContent = [];
  }

  isAllExternalContentAllowed() {
    return this.externalContentService.isAllExternalContentAllowed();
  }

  isAnyExternalContentAllowed() {
    return this.externalContentService.isAnyExternalContentAllowed();
  }

  removeExternalSourceFromList(name: string) {
    this.externalContentService.removePermission(name);
    this.currentAllowedExternalContent = this.currentAllowedExternalContent.filter(item => item !== name)
  }
}
