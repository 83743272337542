import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import {SEOService} from "../../service/seo-service/seo.service";
import {Service} from "./config/service";

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

  services: Service[];
  activeService: Service;
  isLoading = true;

  constructor(
    protected seoService: SEOService,
    protected translation: TranslateService
  ) { }

  ngOnInit(): void {

    this.seoService.setPageTitle('menu.services');
    this.seoService.updateKeyWords(['Services', 'Dienste']);

    this.addServices();
  }

  setActiveService(activeServiceName: string) {
    // @ts-ignore
    this.activeService = this.services.find(service => service.name === activeServiceName);
  }

  protected async addServices() {

    const service1 = new Service();
    service1.icon = '/assets/icons/SevData_Logo_farbe.jpg';
    service1.name = await lastValueFrom(this.translation.get('services.service1.title'));
    service1.details = await lastValueFrom(this.translation.get('services.service1.detail'));
    service1.description = await lastValueFrom(this.translation.get('services.service1.description'));
    service1.servicePageLink = '';

    const service2 = new Service();
    service2.icon = '/assets/icons/SevData_Logo_farbe.jpg';
    service2.name = await lastValueFrom(this.translation.get('services.service2.title'));
    service2.details = await lastValueFrom(this.translation.get('services.service2.detail'));
    service2.description = await lastValueFrom(this.translation.get('services.service2.description'));
    service2.servicePageLink = '';

    const service3 = new Service();
    service3.icon = '/assets/icons/SevData_Logo_farbe.jpg';
    service3.name = await lastValueFrom(this.translation.get('services.service3.title'));
    service3.details = await lastValueFrom(this.translation.get('services.service3.detail'));
    service3.description = await lastValueFrom(this.translation.get('services.service3.description'));
    service3.servicePageLink = '';

    this.services = [
      service1,
      service2,
      service3,
    ];

    this.activeService = this.services[0];

    this.isLoading = false;
  }
}
