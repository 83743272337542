import { NgModule } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

import { RhCardComponent } from './rh-card/rh-card.component';
import { RhButtonComponent } from './rh-button/rh-button.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { DataTableComponent } from './data-table/data-table.component';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { ExternalContentComponent } from './external-content/external-content.component';
import { AccordionModule } from 'primeng/accordion';
import { RouterModule } from '@angular/router';
import {InputTextModule} from 'primeng/inputtext';
import { LoadingComponent } from './loading/loading.component';
import {ProgressBarModule} from 'primeng/progressbar';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import {TranslateModule} from "@ngx-translate/core";
import { TextWithPictureComponent } from './text-with-picture/text-with-picture.component';

@NgModule({
  declarations: [
    RhCardComponent,
    RhButtonComponent,
    MainLayoutComponent,
    DataTableComponent,
    ExternalContentComponent,
    LoadingComponent,
    ThemeSwitcherComponent,
    LanguageSwitcherComponent,
    TextWithPictureComponent,
  ],
  imports: [
    ButtonModule,
    CardModule,
    TableModule,
    CommonModule,
    AccordionModule,
    RouterModule,
    FontAwesomeModule,
    InputTextModule,
    ProgressBarModule,
    TranslateModule
  ],
  exports: [
    RhCardComponent,
    RhButtonComponent,
    MainLayoutComponent,
    DataTableComponent,
    ExternalContentComponent,
    FontAwesomeModule,
    LoadingComponent,
    ThemeSwitcherComponent,
    LanguageSwitcherComponent,
    TextWithPictureComponent,
  ]
})
export class SharedModule {}
