<!--
<rh-main-layout *ngIf="!isLoading">
  <div class="grid w-full">
    <div class="col-12">
      <h1 class="text-center" translate="menu.pricing"></h1>
      <div class="text-center mb-8">
        {{"references.intro" | translate}}
      </div>

      <div class="references">
        <ul class="reference-list">

          <li class="left">
            <ul>
              <li>
                <figure class="reference p-5 mb-5" *ngFor="let reference of leftReferences">
                  <img src="/assets/icons/SevData_Signet_black.svg" class="absolute back-image" alt="">
                  <blockquote class="m-0 text">{{ reference.text }}</blockquote>
                  <figcaption class="caption flex justify-content-between mt-4 pt-4 border-top-1">
                    <div>
                      <div class="mb-1">{{ reference.name}}</div>
                      <div class="">{{ reference.position }}</div>
                      <div class="">{{ reference.company }}</div>
                    </div>
                    <div class="icon text-right">
                      <img class="icon-details w-3rem h-3rem" [src]="reference.icon" [alt]="reference.name">
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>

          <li class="middle">
            <ul>
              <li>
                <figure class="reference p-5 mb-5" *ngFor="let reference of middleReferences">
                  <img src="/assets/icons/SevData_Signet_black.svg" class="absolute back-image" alt="">
                  <blockquote class="m-0 text">{{ reference.text }}</blockquote>
                  <figcaption class="caption flex justify-content-between mt-4 pt-4 border-top-1">
                    <div>
                      <div class="mb-1">{{ reference.name}}</div>
                      <div class="">{{ reference.position }}</div>
                      <div class="">{{ reference.company }}</div>
                    </div>
                    <div class="icon text-right">
                      <img class="icon-details w-3rem h-3rem" [src]="reference.icon" [alt]="reference.name">
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>

          <li class="right">
            <ul>
              <li>
                <figure class="reference p-5 mb-5" *ngFor="let reference of rightReferences">
                  <img src="/assets/icons/SevData_Signet_black.svg" class="absolute back-image" alt="">
                  <blockquote class="m-0 text">{{ reference.text }}</blockquote>
                  <figcaption class="caption flex justify-content-between mt-4 pt-4 border-top-1">
                    <div>
                      <div class="mb-1">{{ reference.name}}</div>
                      <div class="">{{ reference.position }}</div>
                      <div class="">{{ reference.company }}</div>
                    </div>
                    <div class="icon text-right">
                      <img class="icon-details w-3rem h-3rem" [src]="reference.icon" [alt]="reference.name">
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</rh-main-layout>
-->

<!-- This example requires Tailwind CSS v3.0+ -->
<div class="bg-white py-12 sm:py-16 pb-4">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-4xl text-center">
      <h2 class="text-lg font-semibold leading-8 tracking-tight text-teal-600">Pricing</h2>
      <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Pricing plans for teams of all sizes</p>
    </div>
    <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.</p>
    <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      <div class="rounded-3xl p-8 ring-1 xl:p-10 ring-gray-200">
        <h3 id="tier-freelancer" class="text-lg font-semibold leading-8 text-gray-900">Freelancer</h3>
        <p class="mt-4 text-sm leading-6 text-gray-600">The essentials to provide your best work for clients.</p>
        <p class="mt-6 flex items-baseline gap-x-1">
          <!-- Price, update based on frequency toggle state -->
          <span class="text-4xl font-bold tracking-tight text-gray-900">$15</span>

          <!-- Payment frequency, update based on frequency toggle state -->
          <span class="text-sm font-semibold leading-6 text-gray-600">/month</span>
        </p>
        <a href="#" aria-describedby="tier-freelancer" class="mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-teal-600 text-white shadow-sm hover:bg-teal-500 focus-visible:outline-teal-600">Buy plan</a>
        <ul role="list" class="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-600">
          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            5 products
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Up to 1,000 subscribers
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Basic analytics
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            48-hour support response time
          </li>
        </ul>
      </div>

      <div class="rounded-3xl p-8 ring-1 xl:p-10 ring-gray-200">
        <h3 id="tier-startup" class="text-lg font-semibold leading-8 text-gray-900">Startup</h3>
        <p class="mt-4 text-sm leading-6 text-gray-600">A plan that scales with your rapidly growing business.</p>
        <p class="mt-6 flex items-baseline gap-x-1">
          <!-- Price, update based on frequency toggle state -->
          <span class="text-4xl font-bold tracking-tight text-gray-900">$30</span>

          <!-- Payment frequency, update based on frequency toggle state -->
          <span class="text-sm font-semibold leading-6 text-gray-600">/month</span>
        </p>
        <a href="#" aria-describedby="tier-startup" class="mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-teal-600 text-white shadow-sm hover:bg-teal-500 focus-visible:outline-teal-600">Buy plan</a>
        <ul role="list" class="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-600">
          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            25 products
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Up to 10,000 subscribers
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Advanced analytics
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            24-hour support response time
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Marketing automations
          </li>
        </ul>
      </div>

      <div class="rounded-3xl p-8 ring-1 xl:p-10 bg-gray-900 ring-gray-900">
        <h3 id="tier-enterprise" class="text-lg font-semibold leading-8 text-white">Enterprise</h3>
        <p class="mt-4 text-sm leading-6 text-gray-300">Dedicated support and infrastructure for your company.</p>
        <p class="mt-6 flex items-baseline gap-x-1">
          <span class="text-4xl font-bold tracking-tight text-white">Custom</span>
        </p>
        <a href="#" aria-describedby="tier-enterprise" class="mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white">Contact sales</a>
        <ul role="list" class="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-300">
          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Unlimited products
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Unlimited subscribers
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Advanced analytics
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            1-hour, dedicated support response time
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Marketing automations
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Custom reporting tools
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- This example requires Tailwind CSS v3.0+ -->
<div class="relative bg-gray-900">
  <div class="relative h-80 overflow-hidden bg-teal-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
    <img class="h-full w-full object-cover" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply" alt="">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 926 676" aria-hidden="true" class="absolute left-24 -bottom-24 w-[57.875rem] transform-gpu blur-[118px]">
      <path fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)" fill-opacity=".4" d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z" />
      <defs>
        <linearGradient id="60c3c621-93e0-4a09-a0e6-4c228a0116d8" x1="926.392" x2="-109.635" y1=".176" y2="321.024" gradientUnits="userSpaceOnUse">
          <stop stop-color="#776FFF"></stop>
          <stop offset="1" stop-color="#FF4694"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div class="relative mx-auto max-w-7xl py-24 sm:py-32 lg:py-40 lg:px-8">
    <div class="pr-6 pl-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
      <h2 class="text-base font-semibold leading-7 text-teal-400">Award winning support</h2>
      <p class="mt-2 text-4xl font-bold tracking-tight text-white">We’re here to help</p>
      <p class="mt-6 text-base leading-7 text-gray-300">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas tempus tellus etiam sed. Quam a scelerisque amet ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat quisque ut interdum tincidunt duis.</p>
      <div class="mt-8">
        <a href="#" class="inline-flex rounded-md bg-white/10 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Visit the help center</a>
      </div>
    </div>
  </div>
</div>