<div class="relative isolate px-6 pt-14 lg:px-8">
  <div class="absolute inset-x-0 -z-10 overflow-hidden w-full h-full">
    <app-lines [amountOfLines]="32"></app-lines>
  </div>
  <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
    <div class="text-center">
      <h1 class="text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl" data-aos="fade-up" data-aos-duration="1000" [innerHTML]="'landing.hero.subtitle' | translate"></h1>
      <p class="mt-6 text-lg leading-8 text-gray-600" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="landing.hero.title"></p>
      <div class="mt-10 flex items-center cursor-pointer flex justify-center gap-x-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
        <div class="inline-block mx-auto relative">
          <div (click)="scrollToElement(target)" class="text-sm font-semibold leading-6 text-gray-900 scroll" style="padding-left: 28px" translate="landing.hero.button"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-gray-800 py-24 sm:py-32" #target>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <h2 class="text-lg font-semibold leading-8 tracking-tight text-teal-600" data-aos="fade-up" data-aos-duration="1000" translate="landing.features.title"></h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="landing.features.subtitle"></p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div class="flex flex-col" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
            <svg class="h-5 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
            </svg>
            <span translate="landing.features.streamline.heading"></span>
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
            <p class="flex-auto" translate="landing.features.streamline.text"></p>
          </dd>
        </div>

        <div class="flex flex-col" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
            <svg class="h-5 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
            </svg>
            <span translate="landing.features.efficient.heading"></span>
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
            <p class="flex-auto" translate="landing.features.efficient.text"></p>
          </dd>
        </div>

        <div class="flex flex-col" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
            <svg class="h-5 w-5 flex-none text-teal-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
            </svg>
            <span translate="landing.features.customize.heading"></span>
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-white">
            <p class="flex-auto" translate="landing.features.customize.text"></p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>

<div class="relative isolate py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl sm:text-center">
      <h2 class="text-lg font-semibold leading-8 tracking-tight text-teal-600" data-aos="fade-up" data-aos-duration="1000" translate="landing.app.hero.title"></h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="landing.app.hero.subtitle"></p>
    </div>
  </div>
  <div class="relative overflow-hidden pt-16" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <img src="assets/images/app-mockup_de.png" *ngIf="lang === 'de'" alt="App screenshot" class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" width="2432" height="1442">
      <img src="assets/images/app-mockup_en.png" *ngIf="lang !== 'de'" alt="App screenshot" class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" width="2432" height="1442">

      <div class="relative" aria-hidden="true">
        <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
      </div>
    </div>
  </div>
  <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
    <dl class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-x-8 lg:gap-y-16">
      <div class="relative pl-9">
        <dt class="inline font-semibold text-gray-900">
          <svg class="absolute top-1 left-1 h-5 w-5 text-teal-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
          </svg>

          <span translate="landing.app.fulfillment.title" class="mr-2"></span>
        </dt>
        <dd class="inline" translate="landing.app.fulfillment.text"></dd>
      </div>

      <div class="relative pl-9">
        <dt class="inline font-semibold text-gray-900">
          <svg class="absolute top-1 left-1 h-5 w-5 text-teal-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
          </svg>

          <span translate="landing.app.ecommerce.title" class="mr-2"></span>
        </dt>
        <dd class="inline" translate="landing.app.ecommerce.text"></dd>
      </div>
    </dl>
  </div>
</div>


<!-- This example requires Tailwind CSS v3.0+ -->
<div class="bg-gray-800 py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl sm:text-center">
      <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl" translate="landing.pricing.heading"></h2>
      <p class="mt-6 text-lg leading-8 text-white" translate="landing.pricing.text"></p>
    </div>
    <div class="bg-white mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
      <div class="p-8 sm:p-10 lg:flex-auto">
        <h3 class="text-2xl font-bold tracking-tight text-gray-900" translate="landing.pricing.quota"></h3>
        <p class="mt-6 text-base leading-7 text-gray-600" translate="landing.pricing.info"></p>
        <div class="mt-10 flex items-center gap-x-4">
          <h4 class="flex-none text-sm font-semibold leading-6 text-teal-600" translate="landing.pricing.included.head"></h4>
          <div class="h-px flex-auto bg-gray-100"></div>
        </div>
        <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span translate="landing.pricing.included.invoice"></span>
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span translate="landing.pricing.included.monthly"></span>
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span translate="landing.pricing.included.monitoring"></span>
          </li>

        </ul>
      </div>
      <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
        <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
          <div class="mx-auto max-w-xs px-8">
            <p class="text-base font-semibold text-gray-600" translate="landing.pricing.details.heading"></p>
            <p class="mt-6 flex items-baseline justify-center gap-x-2">
              <span class="text-5xl font-bold tracking-tight text-gray-900" translate="landing.pricing.details.euro"></span>
              <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600" translate="landing.pricing.details.currency"></span>
            </p>
            <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3/group/11/create/42" target="_blank" class="mt-10 block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="landing.pricing.details.button"></a>
            <p class="mt-6 text-xs leading-5 text-gray-600" translate="landing.pricing.details.info"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="bg-white py-24 sm:py-32" id="partner">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <h2 class="text-lg font-semibold leading-8 tracking-tight text-teal-600" data-aos="fade-up" data-aos-duration="1000" translate="partner.headline"></h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="partner.text"></p>
      </div>
    </div>

    <div class="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-1 mt-10">
      <div class="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
        <img class="self-start" style="width: 300px" src="assets/images/partner/FT_Logo_2022_RZ_RGB.png" alt="">
        <figure class="mt-10 flex flex-auto flex-col justify-between">
          <blockquote class="text-lg leading-8 text-gray-900">
            <p translate="partner.fft.text"></p>
          </blockquote>
        </figure>
      </div>
    </div>
  </div>
</section>


