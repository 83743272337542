<div class="language-container">
  <span
    class="language"
    (click)="setLanguage('en')"
    [class.active]="currentLanguage === 'en'"
  >{{ 'menu.en' | translate }}</span> |
  <span
    class="language"
    (click)="setLanguage('de')"
    [class.active]="currentLanguage === 'de'"
  >{{ 'menu.de' | translate }}</span>
</div>
