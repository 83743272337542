import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {SEOService} from './modules/service/seo-service/seo.service';
import {ThemeService} from './modules/service/theme-service/theme.service';
import {ExternalContentService} from './modules/service/external-content-service/external-content.service';
import {ChildrenOutletContexts} from '@angular/router';
import {slideInAnimation} from './animations';
import {SeoBuilderService} from './modules/service/seo-builder/seo-builder.service';
import {faMoon, faSun} from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent implements OnInit {

  constructor(
    protected primengConfig: PrimeNGConfig,
    protected seoService: SEOService,
    protected seoBuilder: SeoBuilderService,
    protected themeService: ThemeService,
    protected externalContentService: ExternalContentService,
    protected contexts: ChildrenOutletContexts,
  ) {
  }

  ngOnInit(): void {

    this.primengConfig.ripple = false;
    this.buildHeadInfo();
    this.buildThemes();
    this.externalContentService.buildLocalstorageItems();
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  protected buildHeadInfo() {

    this.seoService.setMainTitle('SevData');
    this.seoService.setDefaultKeywords([
      'sevdata',
    ]);

    this.seoBuilder
      // TODO rh ALLES AN SEVDATA ANPASSEN
      .addMetaTag('og:url', 'https://www.sevdata.de/')
      .createLink('canonical', 'https://www.sevdata.de/')
      .addMetaTag('description', 'IT service provider for integrating systems in the logistics and e-commerce sector.')
      .addMetaTag('robots', 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1')
      .addMetaTag('google-site-verification', 'KSWALuFWSnsAwPU2Jhc7AyHs7G5_qmNDcw8qyo0u_Ag')
      .addMetaTag('keywords', this.seoService.getDefaultKeywords().join(', '))
      .addMetaTag('viewport', 'width=device-width, initial-scale=1.0, shrink-to-fit=no')
      .addMetaTag('msapplication-TileColor', '#000000')
      .addMetaTag('msapplication-config', '/favicon/browserconfig.xml')
      .addMetaTag('theme-color', '#000000')
      .addMetaTag('og:locale', 'de_DE')
      .addMetaTag('og:type', 'website')
      .addMetaTag('og:title', 'SevData')
      .addMetaTag('og:description', 'IT service provider for integrating systems in the logistics and e-commerce sector.')
      .addMetaTag('og:site_name', 'SevData')
      .createLink('icon', 'assets/icons/favicon.png', [ { name: 'type', value: 'image/x-icon' }])
      .createLink('apple-touch-icon', '/favicon/apple-touch-icon.png', [ { name: 'sizes', value: '180x180' } ])
      // .createLink('icon', '/favicon/airborne_logo-1-32x32.png', [ { name: 'type', value: 'image/png' }, { name: 'sizes', value: '32x32' } ])
      // .createLink('icon', '/favicon/airborne_logo-1-192x192.png', [ { name: 'type', value: 'image/png' }, { name: 'sizes', value: '192x192' } ])
      .createLink('mask-icon', '/favicon/safari-pinned-tab.svg', [ { name: 'color', value: '#000000'} ])
      .createLink('alternate', '/feed.xml', [ { name: 'type', value: 'application/rss+xml' } ])
      // .createLink('manifest', '/favicon/site.webmanifest')
      .build();
  }

  protected buildThemes() {

    this.themeService
      .addTheme('theme-light', faSun as IconDefinition)
      .build();
  }
}
