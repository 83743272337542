<div class="relative isolate px-6 pt-14 lg:px-8">
    <div class="absolute inset-x-0 -z-10 overflow-hidden w-full h-full">
        <app-lines [amountOfLines]="32"></app-lines>
    </div>
    <div class="mx-auto max-w-2xl py-16 sm:py-24 lg:py-28">
        <div class="text-center">
            <h1 class="text-5xl font-bold tracking-tight text-gray-900 break-words sm:text-5xl" data-aos="fade-up" data-aos-duration="1000" [innerHTML]="'about-us.title.headline' | translate"></h1>
            <p class="mt-6 text-lg leading-8 text-gray-600" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="about-us.title.subline"></p>
            <div class="mt-10 flex items-center cursor-pointer justify-center gap-x-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                <div class="inline-block mx-auto relative">
                    <div (click)="scrollToElement(target)" class="text-sm font-semibold leading-6 text-gray-900 scroll" style="padding-left: 28px" translate="landing.hero.button"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mx-auto w-full text-center" #target>
    <div class="mx-auto w-full bg-gray-800 py-24 sm:py-32">
        <div class="mx-auto max-w-7xl text-left px-6 lg:px-8">
            <h2 class="font-semibold tracking-tight text-teal-600 text-4xl mb-10" [innerHTML]="'about-us.company.headline' | translate"></h2>
            <p class="text-white" [innerHTML]="'about-us.company.text1' | translate"></p>
            <p class="text-white mt-2" [innerHTML]="'about-us.company.text2' | translate"></p>
            <p class="text-white mt-2" [innerHTML]="'about-us.company.text3' | translate"></p>
            <p class="text-white mt-2" [innerHTML]="'about-us.company.text4' | translate"></p>
            <p class="text-white mt-2" [innerHTML]="'about-us.company.text5' | translate"></p>
            <p class="text-white mt-2" [innerHTML]="'about-us.company.text6' | translate"></p>
            <p class="text-white mt-2" [innerHTML]="'about-us.company.text7' | translate"></p>
        </div>
    </div>
    <div class="py-24 sm:py-32">
        <h2 class="font-semibold text-center tracking-tight text-teal-600 text-4xl mb-20" translate="about-us.team.headline"></h2>
        <ul role="list" class="mx-auto mt-10 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 sm:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            <li>
                <img class="mx-auto  w-56 rounded-full" src="/assets/images/team/ds.jpg" alt="">
                <h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900" translate="about-us.daniel.name"></h3>
                <p class="text-sm leading-6 text-gray-600" translate="about-us.daniel.text"></p>
                <p class="text-sm leading-6 text-gray-600" translate="about-us.daniel.subline"></p>
            </li>

            <li>
                <img class="mx-auto  w-56 rounded-full" src="/assets/images/team/sm.jpg" alt="">
                <h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900" translate="about-us.sven.name"></h3>
                <p class="text-sm leading-6 text-gray-600" translate="about-us.sven.text"></p>
                <p class="text-sm leading-6 text-gray-600" translate="about-us.sven.subline"></p>
            </li>

            <li>
                <img class="mx-auto  w-56 rounded-full" src="/assets/images/team/cw.jpg" alt="">
                <h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900" translate="about-us.christoph.name"></h3>
                <p class="text-sm leading-6 text-gray-600" translate="about-us.christoph.text"></p>
                <p class="text-sm leading-6 text-gray-600" translate="about-us.christoph.subline"></p>
            </li>

            <li>
                <img class="mx-auto  w-56 rounded-full" src="/assets/images/team/vw.jpg" alt="">
                <h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900" translate="about-us.vivien.name"></h3>
                <p class="text-sm leading-6 text-gray-600" translate="about-us.vivien.text"></p>
                <p class="text-sm leading-6 text-gray-600" translate="about-us.vivien.subline"></p>
            </li>
        </ul>
    </div>

    <div class="mx-auto w-full bg-gray-800 py-24 sm:py-32">
        <div class="mx-auto max-w-7xl text-left px-6 lg:px-8 text-left">
            <h3 class="text-lg font-bold text-white tracking-tight" translate="about-us.why.headline"></h3>

            <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600">
                <li class="flex gap-x-3">
                    <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white" translate="about-us.why.bullet1"></span>
                </li>

                <li class="flex gap-x-3">
                    <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white" translate="about-us.why.bullet2"></span>
                </li>

                <li class="flex gap-x-3">
                    <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white" translate="about-us.why.bullet3"></span>
                </li>

                <li class="flex gap-x-3">
                    <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white" translate="about-us.why.bullet4"></span>
                </li>

                <li class="flex gap-x-3">
                    <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-white" translate="about-us.why.bullet5"></span>
                </li>
            </ul>

            <div class="mt-2">
                <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3/group/11/create/42" target="_blank" class="mt-10 inline-block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="landing.pricing.details.button"></a>
            </div>
        </div>
    </div>
</div>