import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import paths from 'src/paths';
import {SEOService} from '../../service/seo-service/seo.service';
import AOS from "aos";


@Component({
    selector: 'air-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, AfterViewInit {

    lang: string = 'en';
    fragment: string | null = null

    constructor(
        protected seoService: SEOService,
        protected translate: TranslateService,
        protected route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {

        AOS.init();

        this.seoService.setPageTitle(this.translate.instant('title'));
        this.seoService.updateKeyWords(['SevData', 'Data Interchange', 'DIaaS', 'Xentral', 'eCommerce', 'Fulfillment', 'Integration', 'Data Exchange'])

        this.translate.onLangChange.subscribe((lang) => {
            this.lang = lang.lang;
        });

        this.route.fragment.subscribe(fragment => { this.fragment = fragment });
    }

    ngAfterViewInit(): void {

        console.log(this.fragment);
        console.log(document.querySelector('#' + this.fragment))
        try {
            setTimeout(() => {
                document.querySelector('#' + this.fragment)!.scrollIntoView();
            }, 150)
        } catch (e) { }
    }

    get paths() {
        return paths;
    }

    scrollToElement(target: HTMLDivElement) {
        target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
}
