<h2 id="footer-heading" class="sr-only">Footer</h2>
<div class="mx-auto max-w-7xl px-6 pt-4 pb-8 lg:px-8 lg:pt-4 w-full">
  <div class="pt-6 md:flex md:items-center md:justify-between">
    <div class="md:flex space-x-0 md:space-x-6 md:order-2 grid grid-cols-2 gap-4">

      <a href="https://www.linkedin.com/company/sevdata/" target="_blank" class="text-base font-medium text-gray-500 hover:text-gray-900 underline">LinkedIn</a>

      <a [routerLink]="'/imprint'" class="text-base font-medium text-gray-500 hover:text-gray-900 underline" translate="menu.imprint"></a>

      <a [routerLink]="'/privacy'" class="text-base font-medium text-gray-500 hover:text-gray-900 underline" translate="menu.privacy"></a>

      <p class="text-base text-gray-400 md:order-1 md:mt-0 visible md:collapse">&copy; 2023 SevData GmbH</p>

      <app-language-switcher></app-language-switcher>
    </div>
    <p class="mt-8 text-base text-gray-400 md:order-1 md:mt-0 collapse md:visible">&copy; 2023 SevData GmbH</p>
  </div>
</div>