import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import {SEOService} from "../../service/seo-service/seo.service";
import {Reference} from "./config/reference";

@Component({
  selector: 'app-references-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent implements OnInit {

  leftReferences: Reference[];
  middleReferences: Reference[];
  rightReferences: Reference[];
  isLoading = true;

  constructor(
    protected seoService: SEOService,
    protected translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.seoService.setPageTitle('menu.pricing');
    this.seoService.updateKeyWords(['Pricing', 'Preise']);

    this.addReferences();
  }

  protected async addReferences() {

    const reference1 = new Reference();
    reference1.name = 'Jörn Jesko';
    reference1.icon = '/assets/icons/SevData_Signet_farbe.jpg';
    reference1.position = await lastValueFrom(this.translate.get('references.bonum.position'));
    reference1.company = await lastValueFrom(this.translate.get('references.bonum.company'));
    reference1.text = await lastValueFrom(this.translate.get('references.bonum.quote'));

    const reference2 = new Reference();
    reference2.name = 'Max Mustermann';
    reference2.icon = '/assets/icons/SevData_Signet_black.jpg';
    reference2.position = await lastValueFrom(this.translate.get('references.acin.position'));
    reference2.company = await lastValueFrom(this.translate.get('references.acin.company'));
    reference2.text = await lastValueFrom(this.translate.get('references.acin.quote'));

    const reference3 = new Reference();
    reference3.name = 'Klaus Kleber';
    reference3.icon = '/assets/icons/SevData_Logo__Kasten_farbe.jpg';
    reference3.position = await lastValueFrom(this.translate.get('references.rnr.position'));
    reference3.company = await lastValueFrom(this.translate.get('references.rnr.company'));
    reference3.text = await lastValueFrom(this.translate.get('references.rnr.quote'));

    // const reference4 = new Reference();
    // reference4.name = 'Name 2';
    // reference4.icon = '/assets/icons/SevData_Signet_farbe.jpg';
    // reference4.position = 'Position 2';
    // reference4.text = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod';

    // const reference5 = new Reference();
    // reference5.name = 'Name 3';
    // reference5.icon = '/assets/icons/SevData_Hub_Logo.svg';
    // reference5.position = 'Position 3';
    // reference5.text = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod';

    // const reference6 = new Reference();
    // reference6.name = 'Name 4';
    // reference6.icon = '/assets/icons/SevData_Signet_farbe.jpg';
    // reference6.position = 'Position 4';
    // reference6.text = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod';

    // const reference7 = new Reference();
    // reference7.name = 'Name 5';
    // reference7.icon = '/assets/icons/SevData_Signet_farbe.jpg';
    // reference7.position = 'Position 5';
    // reference7.text = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod';

    this.leftReferences = [
      reference1,
    ];

    this.middleReferences = [
      reference2,
      // reference4,
      // reference5,
    ];

    this.rightReferences = [
      reference3,
      // reference6,
      // reference7,
    ];

    this.isLoading = false;
  }
}
