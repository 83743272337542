import { Component, OnInit } from '@angular/core';
import {HeaderMenuBuilder} from './builder/header-menu-builder';
import {HeaderMenu} from './config/header-menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  headerMenuConfig: HeaderMenu;

  constructor(
      protected headerMenuBuilder: HeaderMenuBuilder,
  ) {}

  ngOnInit(): void {

    this.buildMenuItems();
  }

  protected buildMenuItems() {

    this.headerMenuBuilder
        .clear()
        .addIcon('./assets/icons/SevData_Logo_farbe.png', 'SevData')
        .addItem('menu.start', '/start')
        .addItem('menu.services', '/services')
        // .addItem('menu.products', '/products')
        .addItem('menu.pricing', '/pricing')
        .addItem('menu.aboutus', '/about')

    this.headerMenuConfig = this.headerMenuBuilder.buildConfig();
  }
}
