<div class="relative bg-white">
    <div class="mx-auto flex max-w-7xl items-center justify-between p-6 md:justify-start md:space-x-10 lg:px-8">
        <div class="flex justify-start lg:w-0 lg:flex-1">
            <a [routerLink]="['/']" class="flex">
                <img class="h-8 w-auto mr-3 sm:h-10 inline-block" [src]="headerMenuConfig.icon.path" [alt]="headerMenuConfig.icon.alt">
            </a>

            <a [routerLink]="['/about']" class="text-base font-medium text-gray-500 hover:text-gray-900 ml-6 mt-2" translate="menu.aboutus"></a>
        </div>


        <nav class="hidden space-x-10 md:flex">
        </nav>
        <div class="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <span class="mr-4 inline-block" translate="menu.customers"></span>
            <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3" target="_blank" class="text-base font-medium text-gray-500 hover:text-gray-900 mr-6" translate="menu.support"></a>
            <a href="https://app.sevdata.io/" target="_blank" class="text-base font-medium text-gray-500 hover:text-gray-900 mr-6" translate="menu.login"></a>

            <app-language-switcher></app-language-switcher>
        </div>
    </div>


    <div class="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden">
        <div class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="px-5 pt-5 pb-6">
                <div class="flex items-center justify-between">
                    <div class="flex">
                        <img [routerLink]="['/']" class="h-8 w-auto mr-3" [src]="headerMenuConfig.icon.path" [alt]="headerMenuConfig.icon.alt">
                    </div>

                </div>
            </div>
            <div class="py-6 px-5">
                <div class="grid grid-cols-2 gap-4">
                    <a [routerLink]="['/about']" class="inline-block col-span-2" translate="menu.aboutus"></a>
                    <!-- <span class="mr-4 inline-block" translate="menu.customers"></span>
                    <span></span> -->
                    <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3" target="_blank" class="text-base font-medium text-gray-900 hover:text-gray-700" translate="menu.support"></a>
                    <a href="https://app.sevdata.io/" target="_blank" class="text-base font-medium text-gray-900 hover:text-gray-700" translate="menu.login"></a>
                </div>
            </div>
        </div>
    </div>
</div>