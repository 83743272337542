import {NgModule} from '@angular/core';
import {DecorationsModule} from '../decorations/decorations.module';
import {LandingPageComponent} from "./landing-page/landing-page.component";
import {SharedModule} from "../shared/shared.module";
import {PrivacyPageComponent} from "./privacy-page/privacy-page.component";
import {ChipModule} from "primeng/chip";
import {ImprintPageComponent} from "./imprint-page/imprint-page.component";
import {ProductOverviewPageComponent} from './product-overview-page/product-overview-page.component';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {ServicesPageComponent} from './services-page/services-page.component';
import {PricingPageComponent} from './pricing-page/pricing-page.component';
import {TranslateModule} from "@ngx-translate/core";
import {DetailsComponent} from './product-overview-page/details/details.component';
import {AboutUsComponent} from './about-us/about-us.component';

@NgModule({
    declarations: [
        LandingPageComponent,
        PrivacyPageComponent,
        ImprintPageComponent,
        ProductOverviewPageComponent,
        ServicesPageComponent,
        PricingPageComponent,
        DetailsComponent,
        AboutUsComponent,
    ],
    providers: [],
    imports: [
        SharedModule,
        ChipModule,
        CommonModule,
        RouterModule,
        TranslateModule,
        DecorationsModule,
    ]
})
export class PagesModule {
}
